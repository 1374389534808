<template>
  <table>
    <thead>
      <tr>
        <th class="strategy-column"></th>
        <th class="allowed-column"></th>
        <th class="notes-column"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Fractional Options</td>
        <td>
          <StringSwitch
            v-model:checked="otherConfigCopy.fractionalOptionsEnabled"
            trueString="Enabled"
            falseString="Disabled"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Enabling fractional options will allow option quantities to be
          divisible into units of 0.01 for pairing purposes.
        </td>
      </tr>
    </tbody>
  </table>
</template>
      
  <script>
import StringSwitch from "./StringSwitch.vue";

export default {
  components: {
    StringSwitch,
  },
  props: {
    otherConfig: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      otherConfigCopy: { ...this.otherConfig },
    };
  },
  methods: {
    handleEdit() {
      this.$emit("edit-other-config", this.otherConfigCopy);
    },
  },
};
</script>
      
  <style>
table {
  border-collapse: collapse;
}

td {
  padding-top: 10px;
}

.strategy-column {
  width: 180px;
}
.allowed-column {
  width: 180px;
}
.notes-column {
  width: 520px;
}
</style>