<template>
  <!-- {{ position }} -->
  <div class="position-result-container">
    <span class="quantity"> {{ quantityString }}</span>
    <span v-if="position.type == 'stock'">
      <span class="stock-shares">{{ position.symbol }} Shares</span>
    </span>
    <span v-else>
      <span class="expiry">{{ displayExpiration }}</span>
      <span class="strike">{{ displayStrike }}</span>
      <span class="call-put">{{ position.call_or_put }}</span>
      <div v-if="typeof position.root === 'string'" class="root-badge">
        {{ position.root }}
      </div>
    </span>
    <span class="price"> {{ displayPrice }}</span>
    <span class="symbol">
      <pre>({{ position.symbol }})</pre>
      <!-- <pre>({{ position.symbol }})</pre> -->
    </span>
  </div>
</template>
    
<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    quantityString() {
      let sign = "";
      if (this.position.qty > 0) {
        sign = "+";
      } else if (this.position.qty < 0) {
        sign = "-";
      }
      return sign + Math.abs(this.position.qty).toLocaleString("en-US");
    },
    displayExpiration() {
      if (!this.position.type == "stock") return "";
      const d = new Date(this.position.expiry);
      return d.toLocaleDateString("en-US", { timeZone: "UTC" });
    },
    displayStrike() {
      return this.position.strike.toLocaleString("en-US");
    },
    displayPrice() {
      return (
        "@" +
        this.position.price.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    },
  },
  methods: {},
};
</script>
<style>
.position-result-container {
  margin: 5px;
  text-align: left;
  margin-left: 20px;
  position: relative;
}

.quantity,
.stock-shares,
.expiry,
.strike,
.call-put,
.price,
.symbol {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.quantity {
  min-width: 90px;
  background-color: var(--blue-grey-2);
  border: solid var(--blue-grey-2);
  color: white;
  text-align: right;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.expiry {
  min-width: 110px;
  text-align: center;
  background-color: var(--blue-grey-3);
  border: solid var(--blue-grey-3);
  color: var(--light-gray);
  /* color: white; */
}
.strike {
  min-width: 70px;
  text-align: center;
  background-color: var(--blue-grey-4);
  border: solid var(--blue-grey-4);
  color: white;
}
.call-put {
  width: 32px;
  background-color: var(--blue-grey-5);
  border: solid var(--blue-grey-5);
  color: white;
}
.price {
  /* background-color: var(--blue-grey-6); */
  min-width: 100px;
  /* border: solid var(--blue-grey-6);
  color: var(--blue-grey-6); */
  border: solid var(--blue-grey-2);
  color: var(--blue-grey-2);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.stock-shares {
  min-width: 212px;
  background-color: var(--blue-grey-4);
  border: solid var(--blue-grey-4);
  color: white;
  text-align: left;
}
.symbol {
  color: var(--blue-grey-5);
}
pre {
  margin: 0px;
}

.root-badge {
  border-radius: 1.25rem;
  text-align: center;
  font-size: 0.67rem;
  height: 18px;
  line-height: 17px;
  border: 1px solid var(--blue-grey-2);
  background-color: var(--blue-grey-2);
  color: #fff;
  position: absolute;
  width: 40px;
  left: 375px;
  bottom: 13px;
  white-space: nowrap;
}
</style>