<template>
  <div class="app-container">
    <h1>Option Pairing & Margin Calculator</h1>
    <div class="intput-output-div">
      <div class="input-output-title">Inputs</div>
      <tabs :options="{ useUrlFragment: false }">
        <tab name="Positions" :suffix="positionsSuffix">
          <PositionTable
            :positions="positions"
            @remove-position="handleRemovePosition"
            @add-position="handleAddPosition"
            @edit-position="handleEditPosition"
          />
        </tab>
        <tab name="UL Prices" :suffix="ulPricesSuffix">
          <UnderlyingPriceTable
            :underlyingPrices="underlyingPrices"
            @edit-ul-price="handleEditUlPrice"
          />
        </tab>
        <tab name="Margin Requirements" :suffix="marginReqsSuffix">
          <ElevatedReqTableVue
            :marginReqs="marginReqs"
            @edit-elevatedReq="handleEditMarginReq"
          />
        </tab>
        <tab name="Non-standard Data" :suffix="nonstandardSuffix">
          <NonstandardTableVue
            :nsItems="nonstandardComponents"
            @remove-nsItem="handleRemoveNonstandard"
            @add-nsItem="handleAddNonstandard"
            @edit-nsItem="handleEditNonstandard"
        /></tab>
        <tab name="Config">
          <div class="config-container">
            <span class="help-header">Permissions</span><br />
            <PermissionsTable
              :permissions="permissions"
              @edit-permissions="handleEditPermissions"
            />
            <br />
            <span class="help-header">Other Config</span><br />
            <OtherConfigTable
              :otherConfig="otherConfig"
              @edit-other-config="handleEditOtherConfig"
            />
          </div>
        </tab>
        <tab name="JSON">
          <pre style="text-align: left">{{
            JSON.stringify(getRequestBody(), null, 4)
          }}</pre>
        </tab>
        <tab name="&#9432; Info">
          <div class="help-text">
            <span class="help-header">Overview</span><br />
            This option pairing algorithm takes a list of equity and/or option
            positions, underlying prices, maintenance margin requirement rates,
            & non-standard deliverable details (if applicable) and returns
            strategies designed to maximize the maintenance margin excess of the
            portfolio.
          </div>
          <div class="help-text">
            <b>Please Note:</b> Since this is a personal side project, I have
            set pretty conservative request limits to limit potential AWS costs.
            If you would like to review a lot of potential test cases or simply
            have any questions, please feel free to reach out to me
            <a
              href="https://www.linkedin.com/in/johnwmccallum/"
              target="_blank"
            >
              <span class="text-link">on Linkedin!</span>
            </a>
          </div>

          <div class="help-text">
            <span class="help-header">Positions</span><br />
            <ul class="help-list">
              <li>
                You can add positions by using the
                <span class="primaryButton">Add Position</span> button at the
                bottom of the
                <span class="tabs-component-tab is-inactive tab-example"
                  >Positions</span
                >tab.
              </li>
              <li>
                You can edit or remove positions by using the corresponding
                <span class="primaryButton">Edit</span> and
                <span class="secondaryButton">Remove</span> buttons.
              </li>
              <li>
                To designate an option as having a non-standard deliverable,
                simply populate the symbol column with the applicable option
                root (instead of the underlying symbol). For example, set the
                symbol to "ABC1" instead of "ABC".
              </li>
            </ul>
          </div>
          <div class="help-text">
            <span class="help-header"
              >Underlying Prices & Margin Requirements</span
            ><br />
            <ul class="help-list">
              <li>
                Symbols will automatically appear in the
                <span class="tabs-component-tab is-inactive tab-example"
                  >Underlying Prices</span
                >and
                <span class="tabs-component-tab is-inactive tab-example"
                  >Margin Requirements</span
                >tabs once at least one position associated with that underlying
                has been added to the
                <span class="tabs-component-tab is-inactive tab-example"
                  >Positions</span
                >tab.
              </li>
              <li>
                You can edit underlying prices and margin requirements by using
                the corresponding
                <span class="primaryButton">Edit</span> buttons.
              </li>
            </ul>
          </div>
          <div class="help-text">
            <span class="help-header">Non-standard Deliverables</span><br />
            The
            <span class="tabs-component-tab is-inactive tab-example"
              >Non-standard Deliverables</span
            >tab lets you
            <span class="primaryButton">Add Non-standard Components</span> which
            are grouped together by root to define the actual deliverable for
            that root.
            <p class="help-text">
              For example, if you wanted to indicate that the root "ABC1" has a
              multiplier of 100 and delivers 25 shares of ABC & $7 cash, you
              would add 2 Non-standard Components with the root set to "ABC1"
              and the multiplier set to 100:
            </p>
            <ol class="help-list">
              <li>
                The first would have a component type of "Shares", the symbol
                would be "ABC", and the Quantity/Amount would be 25.
              </li>
              <li>
                The second would have a component type of "Cash" and the
                Quantity/Amount would be 7.
              </li>
            </ol>
          </div>
        </tab>
      </tabs>
    </div>
    <div class="intput-output-div">
      <div class="input-output-title">Results</div>
      <div v-if="loading">LOADING!!!!</div>
      <div v-else>
        <tabs :options="{ useUrlFragment: false }">
          <tab name="Formatted">
            <div v-if="apiSuccessful">
              <ResultUnderlying
                v-for="underlying in apiResponse.underlyings"
                :key="JSON.stringify(underlying)"
                :underlying="underlying"
              />
            </div>
            <div v-else-if="apiTooManyRequests" class="warning">
              Uh oh! That last request failed because the API has received too
              many requests. Since this is a personal side project, I have set
              very conservative request limits to limit potential AWS costs. If
              you would like to review a lot of potential tests cases, feel free
              to reach out to me via Linkedin!
            </div>
            <div v-else>Error!</div>
          </tab>
          <tab name="JSON">
            <pre style="text-align: left">{{
              JSON.stringify(apiResponse, null, 4)
            }}</pre>
          </tab>
        </tabs>
      </div>
    </div>

    <a href="https://www.linkedin.com/in/johnwmccallum/" target="_blank">
      <div class="credit">
        By: John McCallum
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
          height="20"
        />
      </div>
    </a>
  </div>
</template>

<script>
import PositionTable from "./components/PositionTable.vue";
import UnderlyingPriceTable from "./components/UnderlyingPriceTable.vue";
import ElevatedReqTableVue from "./components/ElevatedReqTable.vue";
import ResultUnderlying from "./components/ResultUnderlying.vue";
import NonstandardTableVue from "./components/NonstandardTable.vue";
import PermissionsTable from "./components/PermissionsTable.vue";
import OtherConfigTable from "./components/OtherConfigTable.vue";

export default {
  name: "App",
  components: {
    PositionTable,
    UnderlyingPriceTable,
    ElevatedReqTableVue,
    ResultUnderlying,
    NonstandardTableVue,
    PermissionsTable,
    OtherConfigTable,
  },
  data() {
    return {
      positions: [
        { isOption: false, symbol: "ABC", quantity: 100, price: 120 },
        {
          isOption: true,
          symbol: "ABC",
          quantity: -1,
          price: 2.5,
          expiration: new Date().toJSON().slice(0, 10),
          strike: 130,
          optionType: true,
        },
      ],
      underlyingPrices: [
        { symbol: "ABC", price: 120.0 },
        { symbol: "NLY", price: 100.0 },
      ],
      marginReqs: [
        {
          symbol: "ABC",
          longStockMaint: 25,
          shortStockMaint: 30,
          shortOptionStandard: 20,
          shortOptionMinimum: 10,
        },
      ],
      nonstandardComponents: [
        {
          root: "NLY1",
          isShares: true,
          symbol: "NLY",
          quantity: 25,
          multiplier: 100,
        },
      ],
      permissions: {
        long_stock_on_margin: true,
        short_stock: true,
        spreads: true,
        uncovered_puts: true,
        uncovered_calls: true,
        strangles: true,
      },
      otherConfig: {
        fractionalOptionsEnabled: false,
      },
      loading: false,
      apiResponse: { statusCode: 200, body: [] },
      apiSuccessful: true,
      apiTooManyRequests: false,
    };
  },
  computed: {
    positionsSuffix() {
      return `<span class='badge'>${this.positions.length}</span>`;
    },
    ulPricesSuffix() {
      if (this.underlyingPrices.length == 0) return "";
      return `<span class='badge'>${this.underlyingPrices.length}</span>`;
    },
    marginReqsSuffix() {
      if (this.marginReqs.length == 0) return "";
      return `<span class='badge'>${this.marginReqs.length}</span>`;
    },
    nonstandardSuffix() {
      if (this.nonstandardComponents.length == 0) return "";
      return `<span class='badge'>${this.nonstandardComponents.length}</span>`;
    },
  },
  methods: {
    handleAddPosition(positionCopy) {
      this.positions.push(positionCopy);
      this.updateUnderlyingPriceOnPositionUpdate(positionCopy);
      this.callApi();
    },
    handleEditPosition(index, positionCopy) {
      this.positions[index] = positionCopy;
      this.updateUnderlyingPriceOnPositionUpdate(positionCopy);
      this.callApi();
    },
    handleRemovePosition(index) {
      this.positions.splice(index, 1);
      this.callApi();
    },
    handleEditUlPrice(editedPrice) {
      const { symbol, price } = editedPrice;
      this.updateUnderylingPrice(symbol, price);
      const index = this.positions.findIndex(
        (p) => p.symbol === symbol && !p.isOption
      );
      if (index > -1) this.positions[index].price = price;
      this.callApi();
    },
    handleEditMarginReq(index, marginReqCopy) {
      this.marginReqs[index] = marginReqCopy;
      this.callApi();
    },
    handleAddNonstandard(nonstandardComponent) {
      this.nonstandardComponents.push(nonstandardComponent);
      this.assureQuoteExists(nonstandardComponent.symbol);
      this.callApi();
    },
    handleEditNonstandard(index, nonstandardComponent) {
      this.nonstandardComponents[index] = nonstandardComponent;
      this.assureQuoteExists(nonstandardComponent.symbol);
      this.callApi();
    },
    handleRemoveNonstandard(index) {
      this.nonstandardComponents.splice(index, 1);
      this.callApi();
    },
    handleEditPermissions(editedPermissions) {
      this.permissions = editedPermissions;
      this.callApi();
    },
    handleEditOtherConfig(editedOtherConfig) {
      this.otherConfig = editedOtherConfig;
      this.callApi();
    },
    handleSubmit() {
      console.log(this.positions);
    },
    priceIndex(symbol) {
      return this.underlyingPrices.findIndex((p) => p.symbol === symbol);
    },
    updateUnderylingPrice(symbol, price) {
      const newPrice = { symbol, price };
      const index = this.priceIndex(symbol);
      if (index > -1) this.underlyingPrices.splice(index, 1);
      this.underlyingPrices.push(newPrice);
      this.underlyingPrices = this.underlyingPrices.sort((a, b) => {
        return a.symbol < b.symbol ? -1 : 1;
      });
    },
    updateUnderlyingPriceOnPositionUpdate(positionCopy) {
      const ul_symbol = positionCopy.symbol.replace(/[0-9]/g, "");
      if (!positionCopy.isOption) {
        this.updateUnderylingPrice(ul_symbol, positionCopy.price);
      } else if (this.priceIndex(ul_symbol) == -1) {
        this.updateUnderylingPrice(ul_symbol, positionCopy.strike);
      }
      this.assureRequirementExists(ul_symbol);
    },
    requirementIndex(symbol) {
      return this.marginReqs.findIndex((r) => r.symbol === symbol);
    },
    assureRequirementExists(symbol) {
      if (this.requirementIndex(symbol) < 0)
        this.marginReqs.push({
          symbol: symbol,
          longStockMaint: 25,
          shortStockMaint: 30,
          shortOptionStandard: 20,
          shortOptionMinimum: 10,
        });
      this.marginReqs = this.marginReqs.sort((a, b) => {
        return a.symbol < b.symbol ? -1 : 1;
      });
    },
    assureQuoteExists(symbol) {
      if (this.priceIndex(symbol) < 0)
        this.updateUnderylingPrice(symbol, 100.0);
    },
    getRequestBody() {
      const positions = this.positions.map((p) => {
        let position = {
          root: p.symbol,
          security_type: p.isOption ? "option" : "stock",
          quantity: p.quantity,
          price: p.price,
        };
        if (p.isOption) {
          position = {
            ...position,
            expiry: p.expiration,
            call_or_put: p.optionType ? "C" : "P",
            strike: p.strike,
          };
        }
        return position;
      });

      let underlying_prices = {};
      for (const up of this.underlyingPrices) {
        underlying_prices[up.symbol] = up.price;
      }

      let elevated_requirements = {};
      for (const er of this.marginReqs) {
        elevated_requirements[er.symbol] = {
          stock_long_maintenance: er.longStockMaint / 100,
          stock_short_maintenance: er.shortStockMaint / 100,
          option_standard: er.shortOptionStandard / 100,
          option_minimum: er.shortOptionMinimum / 100,
        };
      }

      const nonstandard_components = this.nonstandardComponents.map((ns) => {
        let ns_component = {
          root: ns.root,
          component_type: ns.isShares ? "shares" : "cash",
          quantity: ns.quantity,
          multiplier: ns.multiplier,
        };
        if (ns.isShares) {
          ns_component = {
            ...ns_component,
            symbol: ns.symbol,
          };
        }
        return ns_component;
      });

      const allowed_strategies = this.permissions;
      const option_quantity_minimum_increment = this.otherConfig
        .fractionalOptionsEnabled
        ? 0.01
        : 1.0;

      return {
        positions,
        underlying_prices,
        elevated_requirements,
        nonstandard_components,
        allowed_strategies,
        option_quantity_minimum_increment,
      };
    },
    callApi() {
      fetch("https://api.johnwmccallum.com/go/option-pairing-go", {
        method: "POST",
        body: JSON.stringify(this.getRequestBody()),
      })
        .then((response) => {
          this.apiSuccessful = response.status === 200;
          this.apiTooManyRequests = response.status === 429;
          return response.json();
        })
        .then((data) => {
          this.apiResponse = data;
        })
        .catch((error) => {
          console.log("There was an error:", error);
          this.apiSuccessful = false;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.callApi();
  },
};
</script>

<style>
@import "./assets/styles/variables.css";
@import url("https://www.w3schools.com/w3css/4/w3.css");

#app {
  font-family: var(--font-family);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input,
textarea,
select {
  font-family: inherit;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tabs-component {
  /* margin: 4em 0;
  width: 870px; */
  margin: 5px 10px;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 700px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: var(--dark-gray);
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.input-output-title {
  background-color: var(--input-output-color);
  /* background: linear-gradient(135deg, var(--input-output-color) 50%, white); */
  color: white;
  padding: 3px 0;
  font-weight: bold;
  font-size: larger;
}

.intput-output-div {
  border-left: var(--input-output-border);
  width: 890px;
  margin: 5px 10px;
  padding-bottom: 10px;
}

@media (min-width: 700px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(2px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 700px) {
  .tabs-component-panels {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 1em 1em;
  }
}

.prefix,
.badge,
.badge-secondary {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
  margin-left: 0.35em;
}
@media (min-width: 700px) {
  .badge,
  .badge-secondary {
    position: absolute;
    right: -0.725em;
    top: -0.725em;
  }
}

.badge {
  border: 1px solid var(--blue-grey-1);
  background-color: var(--blue-grey-1);
  color: #fff;
}
.badge-secondary {
  border: 1px solid var(--blue-grey-1);
  background-color: #fff;
  color: var(--blue-grey-1);
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.primaryButton,
.secondaryButton {
  border-radius: 8px;
  border-style: solid;
  border-color: var(--button-color);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0 1px;
  outline: none;
  padding: 5px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.primaryButton {
  background-color: var(--button-color);
  color: #ffffff;
}

.primaryButton:hover {
  background-color: var(--button-color-hover);
  border-color: var(--button-color-hover);
}

.secondaryButton {
  background-color: #ffffff;
  color: var(--button-color);
}

.secondaryButton:hover {
  background-color: var(--button-color-hover);
  border-color: var(--button-color-hover);
}

.app-container {
  width: 900px;
  min-width: 900px;
}

.config-container,
.help-text,
.help-header {
  text-align: left;
  margin-top: 15px;
}
.help-header {
  font-weight: bold;
  font-size: 20px;
  color: var(--blue-grey-1);
}
.help-list {
  margin: 0px;
}

.warning {
  border: solid 1px #c68642;
  color: #8d5524;
  background-color: #ffdbac;
  padding: 15px;
}
.credit {
  color: var(--dark-gray);
}
.tab-example {
  padding: 0.4em 0.53em;
}

a {
  text-decoration: none;
}

h1 {
  color: var(--button-color);
  margin-bottom: 0px;
}

.text-link {
  font-weight: bold;
  color: var(--blue-grey-1);
}
</style>
