<template>
  <table>
    <thead>
      <tr>
        <th class="strikeColumn">Symbol</th>
        <th class="strikeColumn">Price</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr />
      <UnderlyingPriceItem
        v-for="underylingPrice in underlyingPrices"
        :key="JSON.stringify(underylingPrice)"
        :underylingPrice="underylingPrice"
        @edit-ul-price="handleEdit($event)"
      />
    </tbody>
  </table>
</template>
    
<script>
import UnderlyingPriceItem from "./UnderlyingPriceItem.vue";

export default {
  components: {
    UnderlyingPriceItem,
  },
  props: {
    underlyingPrices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleEdit(price) {
      const editedPrice = { ...price };
      this.$emit("edit-ul-price", editedPrice);
    },
  },
};
</script>
    
  <style>
input[type="text"] {
  text-transform: uppercase;
  width: 60px;
}

input[type="number"] {
  width: 70px;
}

.strikeColumn {
  width: 80px;
}

.expirationColumn {
  width: 150px;
}

table {
  border-collapse: collapse;
}

tr.newPositionRow td {
  background-color: var(--light-gray);
  height: 50px;
}
</style>