<template>
  <tr>
    <template v-if="!editing">
      <td>
        <StringSwitch
          v-model:checked="positionCopy.isOption"
          trueString="option"
          falseString="stock"
          width="100px"
          disabled
        />
      </td>
      <td class="disabledPositionInput">{{ positionCopy.symbol }}</td>
      <td class="disabledPositionInput">{{ positionCopy.quantity }}</td>
      <td class="disabledPositionInput">
        <span v-if="positionCopy.isOption">{{ displayExpiration }}</span>
      </td>
      <td class="disabledPositionInput">
        <span v-if="positionCopy.isOption">{{ positionCopy.strike }}</span>
      </td>
      <td>
        <StringSwitch
          v-if="positionCopy.isOption"
          v-model:checked="positionCopy.optionType"
          trueString="call"
          falseString="put"
          width="85px"
          disabled
        />
      </td>
      <td class="disabledPositionInput">{{ positionCopy.price }}</td>
      <td>
        <button class="primaryButton" @click="startEditing">Edit</button>
        <button class="secondaryButton" @click="handleRemove">Remove</button>
      </td>
    </template>
    <template v-else>
      <td>
        <StringSwitch
          v-model:checked="positionCopy.isOption"
          trueString="option"
          falseString="stock"
          width="100px"
        />
      </td>
      <td>
        <input
          type="text"
          @focus="$event.target.select()"
          v-model="positionCopy.symbol"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="positionCopy.quantity"
        />
      </td>
      <td>
        <input
          v-if="positionCopy.isOption"
          type="date"
          @focus="$event.target.select()"
          v-model="positionCopy.expiration"
        />
      </td>
      <td>
        <input
          v-if="positionCopy.isOption"
          type="number"
          @focus="$event.target.select()"
          v-model="positionCopy.strike"
        />
      </td>
      <td>
        <StringSwitch
          v-if="positionCopy.isOption"
          v-model:checked="positionCopy.optionType"
          trueString="call"
          falseString="put"
          width="85px"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="positionCopy.price"
        />
      </td>
      <td>
        <button class="primaryButton" @click="handleSave">Save</button>
        <button class="secondaryButton" @click="cancelEditing">Cancel</button>
      </td>
    </template>
  </tr>
</template>
  
<script>
import StringSwitch from "./StringSwitch.vue";

export default {
  components: {
    StringSwitch,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      positionCopy: { ...this.position },
      editing: false,
    };
  },
  computed: {
    displayExpiration() {
      if (!this.positionCopy.expiration) return "";
      const d = new Date(this.positionCopy.expiration);
      return d.toLocaleDateString("en-US", { timeZone: "UTC" });
    },
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
      this.positionCopy = { ...this.position };
    },
    handleSave() {
      this.editing = false;
      this.positionCopy.symbol = this.positionCopy.symbol.toUpperCase();
      this.$emit("edit-position", this.positionCopy);
    },
    handleRemove() {
      this.$emit("remove-position", this.position);
    },
  },
};
</script>

<style scoped>
.sec_type_toggle {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.sec_type_toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.sec_type_toggle .toggle__switch {
  display: inline-block;
  height: 12px;
  border-radius: 6px;
  width: 40px;
  background: #bfcbd9;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  margin-left: 10px;
  transition: all 0.25s;
}

.sec_type_toggle .toggle__switch::after,
.sec_type_toggle .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.sec_type_toggle .toggle__switch::after {
  background: #4d4d4d;
  box-shadow: 0 0 1px #666;
}
.sec_type_toggle .toggle__switch::before {
  background: #4d4d4d;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

input[type="text"] {
  text-transform: uppercase;
}

.disabledPositionInput {
  color: var(--dark-gray);
}
</style>
  