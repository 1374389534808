<template>
  <tr>
    <template v-if="!editing">
      <td class="disabledReqItemInput">{{ reqItemCopy.symbol }}</td>
      <td class="disabledReqItemInput">{{ reqItemCopy.longStockMaint }}%</td>
      <td class="disabledReqItemInput">{{ reqItemCopy.shortStockMaint }}%</td>
      <td class="disabledReqItemInput">
        {{ reqItemCopy.shortOptionStandard }}%
      </td>
      <td class="disabledReqItemInput">
        {{ reqItemCopy.shortOptionMinimum }}%
      </td>
      <td>
        <button class="primaryButton" @click="startEditing">Edit</button>
      </td>
    </template>
    <template v-else>
      <td>
        <input
          type="text"
          @focus="$event.target.select()"
          v-model="reqItemCopy.symbol"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="reqItemCopy.longStockMaint"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="reqItemCopy.shortStockMaint"
        />
      </td>

      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="reqItemCopy.shortOptionStandard"
        />
      </td>

      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="reqItemCopy.shortOptionMinimum"
        />
      </td>

      <td>
        <button class="primaryButton" @click="handleSave">Save</button>
      </td>
    </template>
  </tr>
</template>
  
<script>
export default {
  components: {},
  props: {
    reqItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reqItemCopy: { ...this.reqItem },
      editing: false,
    };
  },
  computed: {},
  methods: {
    startEditing() {
      this.editing = true;
    },
    handleSave() {
      this.editing = false;
      this.reqItemCopy.symbol = this.reqItemCopy.symbol.toUpperCase();
      this.$emit("edit-elevatedReq", this.reqItemCopy);
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  text-transform: uppercase;
}

.disabledReqItemInput {
  color: var(--dark-gray);
}
</style>
  