<template>
  <div class="underlying-result-container">
    <div class="underlying-header">
      <span class="ul-symbol">{{ underlying.underlying }}</span>
      <span class="ul-price">
        @{{
          underlying.underlying_price.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        }}</span
      >
    </div>
    <div class="underlying-subheader">
      <b>Requirement: </b>
      {{
        underlying.maintenance_requirement.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <b>Margin Equity: </b>
      {{
        underlying.margin_equity.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      }}
    </div>
    <!-- <div class="underlying-subheader">
      <span><b>Calculation Time: </b> {{ calculationTime }}</span>
    </div> -->
    <ResultStrategy
      v-for="strategy in underlying.strategies"
      :key="JSON.stringify(strategy)"
      :strategy="strategy"
    />
  </div>
</template>

<script>
import ResultStrategy from "./ResultStrategy.vue";

export default {
  components: {
    ResultStrategy,
  },
  props: {
    underlying: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {},
  computed: {
    calculationTime() {
      return this.underlying.calculation_time.toFixed(6) + " Seconds";
    },
  },
};
</script>
<style>
.underlying-header {
  background-color: var(--underlying-result-color);
  color: white;
}
.ul-symbol {
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
.ul-price {
  vertical-align: center;
  height: 100%;
}
.underlying-subheader {
  background-color: var(--underlying-result-color);
  color: white;
  padding-bottom: 10px;
}
.underlying-result-container {
  border-left: var(--underlying-result-border);
  margin-bottom: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
}
</style>