<template>
  <table>
    <thead>
      <tr>
        <th class="">Symbol</th>
        <th class="reqColumn">Long Stock Maintenance</th>
        <th class="reqColumn">Short Stock Maintenance</th>
        <th class="reqColumn">Short Option Standard</th>
        <th class="reqColumn">Short Option Minimum</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr />
      <ElevatedReqItem
        v-for="(reqItem, index) in marginReqs"
        :key="JSON.stringify(reqItem)"
        :reqItem="reqItem"
        @edit-elevatedReq="handleEdit(index, $event)"
      />
    </tbody>
  </table>
</template>
  
<script>
import ElevatedReqItem from "./ElevatedReqItem.vue";

export default {
  components: {
    ElevatedReqItem,
  },
  props: {
    marginReqs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleEdit(index, elevatedReq) {
      const editedReqItem = { ...elevatedReq };
      this.$emit("edit-elevatedReq", index, editedReqItem);
    },
  },
};
</script>
  
<style>
input[type="text"] {
  text-transform: uppercase;
}

/* input[type="number"] {
  width: 70px;
} */

table {
  border-collapse: collapse;
}

.reqColumn {
  width: 140px;
}
</style>