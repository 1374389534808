<template>
  <table>
    <thead>
      <tr>
        <th class="strategy-column">Strategy</th>
        <th class="allowed-column">Allowed/Disallowed</th>
        <th class="notes-column">Notes</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Long Stock on Margin</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.long_stock_on_margin"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing long stock on margin will result in long stock value being
          removed from margin equity and all stock requirements being set to 0.
        </td>
      </tr>
      <tr>
        <td>Short Stock</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.short_stock"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing short stock will result in a minimum requirement of 100%.
        </td>
      </tr>
      <tr>
        <td>Spreads</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.spreads"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing spreads will prevent short options from being paired with
          long options. Short options can still be paired against stock.
        </td>
      </tr>
      <tr>
        <td>Uncovered Puts</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.uncovered_puts"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing uncovered puts will result in all unpaired short puts
          being treated as "cash secured".
        </td>
      </tr>
      <tr>
        <td>Uncovered Calls</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.uncovered_calls"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing uncovered calls will result in all unpaired short calls
          having their requirement calculation rates raised to a minimum of
          100%/100%.
        </td>
      </tr>
      <tr>
        <td>Straddles/Strangles</td>
        <td>
          <StringSwitch
            v-model:checked="permissionsCopy.strangles"
            trueString="Allowed"
            falseString="Not Allowed"
            width="135px"
            @update:checked="handleEdit"
          />
        </td>
        <td>
          Disallowing straddles/strangles will prevent any unpaired short
          calls/puts being paired with each other.
        </td>
      </tr>
    </tbody>
  </table>
</template>
    
<script>
import StringSwitch from "./StringSwitch.vue";

export default {
  components: {
    StringSwitch,
  },
  props: {
    permissions: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      permissionsCopy: { ...this.permissions },
    };
  },
  methods: {
    handleEdit() {
      this.$emit("edit-permissions", this.permissionsCopy);
    },
  },
};
</script>
    
<style>
table {
  border-collapse: collapse;
}

td {
  padding-top: 10px;
}

.strategy-column {
  width: 180px;
}
.allowed-column {
  width: 180px;
}
.notes-column {
  width: 520px;
}
</style>