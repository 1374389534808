<template>
  <tr>
    <template v-if="!editing">
      <td class="disabledPositionInput">{{ priceCopy.symbol }}</td>
      <td class="disabledPositionInput">{{ priceCopy.price }}</td>
      <td>
        <button class="primaryButton" @click="startEditing">Edit</button>
      </td>
    </template>
    <template v-else>
      <td class="disabledPositionInput">{{ priceCopy.symbol }}</td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="priceCopy.price"
        />
      </td>

      <td>
        <button class="primaryButton" @click="handleSave">Save</button>
      </td>
    </template>
  </tr>
</template>
    
  <script>
export default {
  components: {},
  props: {
    underylingPrice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      priceCopy: { ...this.underylingPrice },
      editing: false,
    };
  },
  computed: {},
  methods: {
    startEditing() {
      this.editing = true;
    },
    handleSave() {
      this.editing = false;
      //   this.priceCopy.key = this.priceCopy.symbol + this.priceCopy.price;
      this.$emit("edit-ul-price", this.priceCopy);
    },
  },
};
</script>
  
  <style scoped>
.sec_type_toggle {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.sec_type_toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.sec_type_toggle .toggle__switch {
  display: inline-block;
  height: 12px;
  border-radius: 6px;
  width: 40px;
  background: #bfcbd9;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  margin-left: 10px;
  transition: all 0.25s;
}

.sec_type_toggle .toggle__switch::after,
.sec_type_toggle .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.sec_type_toggle .toggle__switch::after {
  background: #4d4d4d;
  box-shadow: 0 0 1px #666;
}
.sec_type_toggle .toggle__switch::before {
  background: #4d4d4d;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

input[type="text"] {
  text-transform: uppercase;
}

.disabledPositionInput {
  color: var(--dark-gray);
}
</style>
    