<template>
  <div
    :class="
      strategyAllowed
        ? 'strategy-result-container'
        : 'invalid-strategy-result-container'
    "
    class="strategy-result-container"
  >
    <div
      :class="strategyAllowed ? 'strategy-header' : 'invalid-strategy-header'"
    >
      <span v-if="!strategyAllowed" class="invalid-strategy-icon"
        >&#9888;
      </span>
      <span class="strategy-title"
        ><b>{{ strategy.strategy }}</b></span
      >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <b>Requirement: </b>
      {{
        strategy.maintenance_requirement.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <b>Margin Equity: </b>
      {{
        strategy.margin_equity.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      }}
    </div>
    <ResultPosition
      v-for="position in strategy.positions"
      :key="JSON.stringify(position)"
      :position="position"
    />
    <!-- <pre style="text-align: left">{{ JSON.stringify(strategy, null, 4) }}</pre> -->
  </div>
</template>
  
<script>
import ResultPosition from "./ResultPosition.vue";

export default {
  components: {
    ResultPosition,
  },
  props: {
    strategy: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    strategyAllowed() {
      console.log(this.strategy.disallowed_strategy);
      if ("disallowed_strategy" in this.strategy)
        return !this.strategy.disallowed_strategy;
      return true;
    },
  },
};
</script>
<style>
.strategy-header {
  background-color: var(--strategy-result-color);
  /* color: var(--light-gray); */
  color: white;
}

.strategy-result-container {
  border-left: var(--strategy-result-border);
  margin-bottom: 5px;
  margin-right: 20px;
  padding-bottom: 10px;
  /* margin-right: 30px; */
}

.invalid-strategy-header {
  background-color: var(--invalid-strategy-result-color);
  /* color: var(--light-gray); */
  color: white;
}
.invalid-strategy-result-container {
  border-left: var(--invalid-strategy-result-border);
  margin-bottom: 5px;
  margin-right: 20px;
  padding-bottom: 10px;
  /* margin-right: 30px; */
}

.strategy-title {
  text-transform: capitalize;
  font-size: 20px;
}

.invalid-strategy-icon {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
</style>