<template>
  <table>
    <thead>
      <tr>
        <th class="nsColumn">Root</th>
        <th class="nsColumn">Component Type</th>
        <th class="nsColumn">Symbol</th>
        <th class="nsColumn">Quantity/Amount</th>
        <th class="nsColumn">Multiplier</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr />
      <NonstandardItem
        v-for="(nsItem, index) in nsItems"
        :key="JSON.stringify(nsItem)"
        :nsItem="nsItem"
        @remove-nsItem="handleRemove(index)"
        @edit-nsItem="handleEdit(index, $event)"
      />
      <tr>
        <td colspan="8"></td>
      </tr>
      <tr class="newNsItemRow">
        <td>
          <input
            type="text"
            @focus="$event.target.select()"
            v-model="newNsItem.root"
          />
        </td>
        <td>
          <StringSwitch
            v-model:checked="newNsItem.isShares"
            trueString="shares"
            falseString="cash"
            width="120px"
          />
        </td>
        <td>
          <input
            v-if="newNsItem.isShares"
            type="text"
            @focus="$event.target.select()"
            v-model="newNsItem.symbol"
          />
        </td>
        <td>
          <input
            type="number"
            @focus="$event.target.select()"
            v-model="newNsItem.quantity"
          />
        </td>
        <td>
          <input
            type="number"
            @focus="$event.target.select()"
            v-model="newNsItem.multiplier"
          />
        </td>

        <td>
          <button class="primaryButton" @click="addNsItem">
            Add Non-standard Component
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
import NonstandardItem from "./NonstandardItem.vue";
import StringSwitch from "./StringSwitch.vue";

export default {
  components: {
    NonstandardItem,
    StringSwitch,
  },
  props: {
    nsItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      newNsItem: {
        root: "NLY1",
        isShares: true,
        symbol: "NLY",
        quantity: 25,
        multiplier: 100,
      },
    };
  },
  methods: {
    addNsItem() {
      const newNsItem = { ...this.newNsItem };
      newNsItem.symbol = newNsItem.symbol.toUpperCase();
      newNsItem.root = newNsItem.root.toUpperCase();
      this.$emit("add-nsItem", newNsItem);
      this.newNsItem.root = "NLY1";
      this.newNsItem.isShares = true;
      this.newNsItem.symbol = "NLY";
      this.newNsItem.quantity = 25;
      this.newNsItem.multiplier = 100;
    },
    handleEdit(index, nsItem) {
      const editedNsItem = { ...nsItem };
      this.$emit("edit-nsItem", index, editedNsItem);
    },
    handleRemove(index) {
      this.$emit("remove-nsItem", index);
    },
  },
};
</script>
  
<style>
input[type="text"] {
  text-transform: uppercase;
}

/* input[type="number"] {
  width: 70px;
} */

table {
  border-collapse: collapse;
}

tr.newNsItemRow td {
  background-color: var(--light-gray);
  height: 50px;
}

.nsColumn {
  width: 100px;
}
</style>