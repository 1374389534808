<template>
  <tr>
    <template v-if="!editing">
      <td class="disabledNsItemInput">{{ nsItemCopy.root }}</td>
      <td>
        <StringSwitch
          v-model:checked="nsItemCopy.isShares"
          trueString="shares"
          falseString="cash"
          width="120px"
          disabled
        />
      </td>
      <td class="disabledNsItemInput">
        <span v-if="nsItemCopy.isShares">{{ nsItemCopy.symbol }}</span>
      </td>
      <td class="disabledNsItemInput">{{ quantityDescription }}</td>
      <td class="disabledNsItemInput">{{ nsItemCopy.multiplier }}</td>
      <td>
        <button class="primaryButton" @click="startEditing">Edit</button>
        <button class="secondaryButton" @click="handleRemove">Remove</button>
      </td>
    </template>
    <template v-else>
      <td>
        <input
          type="text"
          @focus="$event.target.select()"
          v-model="nsItemCopy.root"
        />
      </td>
      <td>
        <StringSwitch
          v-model:checked="nsItemCopy.isShares"
          trueString="shares"
          falseString="cash"
          width="120px"
        />
      </td>
      <td>
        <input
          v-if="nsItemCopy.isShares"
          type="text"
          @focus="$event.target.select()"
          v-model="nsItemCopy.symbol"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="nsItemCopy.quantity"
        />
      </td>
      <td>
        <input
          type="number"
          @focus="$event.target.select()"
          v-model="nsItemCopy.multiplier"
        />
      </td>
      <td>
        <button class="primaryButton" @click="handleSave">Save</button>
        <button class="secondaryButton" @click="cancelEditing">Cancel</button>
      </td>
    </template>
  </tr>
</template>
  
<script>
import StringSwitch from "./StringSwitch.vue";

export default {
  components: { StringSwitch },
  props: {
    nsItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nsItemCopy: { ...this.nsItem },
      editing: false,
    };
  },

  computed: {
    quantityDescription() {
      if (this.nsItem.isShares) return this.nsItem.quantity + " Shares";
      return "$" + this.nsItem.quantity;
    },
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
      this.nsItemCopy = { ...this.nsItem };
    },
    handleSave() {
      this.editing = false;
      this.nsItemCopy.root = this.nsItemCopy.root.toUpperCase();
      this.nsItemCopy.symbol = this.nsItemCopy.symbol.toUpperCase();
      this.$emit("edit-nsItem", this.nsItemCopy);
    },
    handleRemove() {
      this.$emit("remove-nsItem", this.nsItem);
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  text-transform: uppercase;
}

.disabledNsItemInput {
  color: var(--dark-gray);
}
</style>
  