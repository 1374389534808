<template>
  <table>
    <thead>
      <tr>
        <th class="strikeColumn">Stock/Option</th>
        <th class="strikeColumn">Symbol</th>
        <th class="strikeColumn">Quantity</th>
        <th class="expirationColumn">Expiration</th>
        <th class="strikeColumn">Strike</th>
        <th class="strikeColumn">Call/Put</th>
        <th class="strikeColumn">Price</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr />
      <PositionItem
        v-for="(position, index) in positions"
        :key="JSON.stringify(position)"
        :position="position"
        @remove-position="handleRemove(index)"
        @edit-position="handleEdit(index, $event)"
      />
      <tr>
        <td colspan="8"></td>
      </tr>
      <tr class="newPositionRow">
        <td>
          <StringSwitch
            v-model:checked="newPosition.isOption"
            trueString="option"
            falseString="stock"
            width="100px"
          />
        </td>
        <td>
          <input
            type="text"
            @focus="$event.target.select()"
            v-model="newPosition.symbol"
          />
        </td>
        <td>
          <input
            type="number"
            @focus="$event.target.select()"
            v-model="newPosition.quantity"
          />
        </td>
        <td>
          <input
            v-if="newPosition.isOption"
            type="date"
            @focus="$event.target.select()"
            v-model="newPosition.expiration"
          />
        </td>
        <td>
          <input
            v-if="newPosition.isOption"
            type="number"
            @focus="$event.target.select()"
            v-model="newPosition.strike"
          />
        </td>
        <td>
          <StringSwitch
            v-if="newPosition.isOption"
            v-model:checked="newPosition.optionType"
            trueString="call"
            falseString="put"
            width="85px"
          />
        </td>
        <td>
          <input
            type="number"
            @focus="$event.target.select()"
            v-model="newPosition.price"
          />
        </td>
        <td>
          <button class="primaryButton" @click="addPosition">
            Add Position
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
import PositionItem from "./PositionItem.vue";
import StringSwitch from "./StringSwitch.vue";

export default {
  components: {
    PositionItem,
    StringSwitch,
  },
  props: {
    positions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      newPosition: {
        isOption: true,
        symbol: "ABC",
        quantity: 1,
        price: 1.5,
        expiration: new Date().toJSON().slice(0, 10),
        strike: 120.0,
        optionType: true,
      },
    };
  },
  methods: {
    addPosition() {
      const newPosition = { ...this.newPosition };
      newPosition.symbol = newPosition.symbol.toUpperCase();
      this.$emit("add-position", newPosition);
      this.newPosition.isOption = true;
      this.newPosition.symbol = "ABC";
      this.newPosition.quantity = 1;
      this.newPosition.price = 1.5;
      this.newPosition.expiration = new Date().toJSON().slice(0, 10);
      this.newPosition.strike = 120;
      this.newPosition.optionType = true;
    },
    handleEdit(index, position) {
      const editedPosition = { ...position };
      this.$emit("edit-position", index, editedPosition);
    },
    handleRemove(index) {
      this.$emit("remove-position", index);
    },
  },
};
</script>
  
<style>
input[type="text"] {
  text-transform: uppercase;
  width: 60px;
}

input[type="number"] {
  width: 70px;
}

.strikeColumn {
  width: 80px;
}

.expirationColumn {
  width: 150px;
}

table {
  border-collapse: collapse;
}

tr.newPositionRow td {
  background-color: var(--light-gray);
  height: 50px;
}
</style>